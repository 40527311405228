import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

//graficos
import ChartFactures from './chartFacturesDay';
import ChartFacturesMounth from './chartFacturesMounth';

//css
import './chartFacturesDay.css';
import './mainCss.css';

//API
import { apiRest } from '../../api/services';

function Main() {
    const userAuth = useSelector(state => state.authentication);
    const [grafDia, setGrafDia] = useState(0);
    const [grafDiaAnt, setGrafDiaAnt] = useState(0);
    const [grafMes, setGrafMes] = useState(0);
    const [grafMesAnt, setGrafMesAnt] = useState(0);

    useEffect(() => {
        apiRest
            .statisticsFactures(userAuth.userKey, userAuth.token)
            .then((res) => {
                setGrafDia(res[0].TotalDia || 0);
                setGrafDiaAnt(res[0].TotalDiaAnt || 0);
                setGrafMes(res[0].TotalMes);
                setGrafMesAnt(res[0].TotalMesAnt);
            })
            .catch((e) => {
                console.log(e)
            })
    }, [userAuth.userKey, userAuth.token])

    return (
        <div className="grid-container">
        <div className="grid-item chart-factures">
            <ChartFactures value1={grafDia} value2={grafDiaAnt} />
        </div>
        <div className="grid-item">
            <ChartFacturesMounth value1={grafMes} value2={grafMesAnt} />
        </div>
        {/* Puedes agregar más elementos aquí si es necesario */}
    </div>
    )
}


export default Main
