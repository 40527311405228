import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { userActions } from '../../redux/auth/action';

function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = handleSubmit((data) => {
        dispatch(userActions.login(data, navigate));
    })

    return (
        <div className="loginRegister">
            <div className="loginRegister__body">
                <div className="loginRegister__header">
                    <img alt="imagen login" src="images/entre-GAS-login.svg" />
                </div>
                <div className="loginRegister__article">
                    <form onSubmit={onSubmit}>
                        <div className="loginRegister__login">
                            <div className="textInput">
                                <label for="inputField">Usuario</label>
                                <input type='text' {...register('userName', { required: true })} className='textInput--Input' id="username"/>
                                {errors.userName && <p>Nombre de usuario requerido.</p>}
    
                                <label for="passwordField">Contraseña</label>
                                <input type='password' {...register('password', { required: true })} className='textInput--Input' id="password"/>
                                {errors.password && <p>Contraseña requerida.</p>}
                            </div>
                            <input type="submit" className="btnPrimary" value='Ingresar' />

                            <div className="loginRegister__footer">
                                <p>
                                    <a href='/register'>Quiero registrarme</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login